import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "tooltip"];

  click() {
    if (!this.textTarget) return;
    const copyText = this.textTarget;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    window.navigator.clipboard.writeText(copyText.value);
    this.tooltipTarget.innerHTML = "¡Added to clipboard!";
  }

  onmouseout() {
    this.tooltipTarget.innerHTML = "Copy";
  }
}
