import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["el"];

  click(e) {
    e.preventDefault();

    this.elTarget.click();
    this.element.reset();
  }
}
