import { Controller } from "@hotwired/stimulus";
import AutoNumeric from "autonumeric";

// Connects to data-controller="currency-input"
export default class extends Controller {
  connect() {
    new AutoNumeric(this.element, {
      digitGroupSeparator: ",",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      currencySymbolPlacement:
        AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod: "D",
      decimalPlaces: 2,
      selectNumberOnly: true,
      unformatOnSubmit: true,
    });
  }
}
