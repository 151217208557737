import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("asda");
  }

  reset() {
    console.log("Adsasda");
    this.element.reset();
  }
}
